export const register_car_constants = {
  SAVE_REGISTER_CAR_INFO_1: "SAVE_REGISTER_CAR_INFO_1",
  SAVE_REGISTER_CAR_INFO_2: "SAVE_REGISTER_CAR_INFO_2",
  SAVE_REGISTER_CAR_INFO_3: "SAVE_REGISTER_CAR_INFO_3",
  SAVE_REGISTER_CAR_INFO_4: "SAVE_REGISTER_CAR_INFO_4",
  SAVE_REGISTER_CAR_INFO_1_EDIT: "SAVE_REGISTER_CAR_INFO_1_EDIT",
  SAVE_REGISTER_CAR_INFO_2_EDIT: "SAVE_REGISTER_CAR_INFO_2_EDIT",
  SAVE_REGISTER_CAR_INFO_3_EDIT: "SAVE_REGISTER_CAR_INFO_3_EDIT",
  SAVE_REGISTER_CAR_INFO_4_EDIT: "SAVE_REGISTER_CAR_INFO_4_EDIT",
  SET_PROVINCE_LIST: "SET_PROVINCE_LIST",
  SAVE_AUCTION_TYPE: "SAVE_AUCTION_TYPE",
  SAVE_STATUS_PROFILE_CAR: "SAVE_STATUS_PROFILE_CAR",
  SAVE_TRANSPOTATION: "SAVE_TRANSPOTATION",
  SAVE_REGISTER_CAR_ID_LIST: "SAVE_REGISTER_CAR_ID_LIST",
  SAVE_TRANSPORTATION_RESPONSE: "SAVE_TRANSPORTATION_RESPONSE",
  CLEAR_TRANSPORTATION_FORM: "CLEAR_TRANSPORTATION_FORM",
  CLEAR_FORM_REGISTER_CAR: "CLEAR_FORM_REGISTER_CAR",
  SAVE_YARD_ADDRESS: "SAVE_YARD_ADDRESS",
};

const config = {};
config.version = "1.0.0.110";
const hostname = window.location.hostname;
config.KeyApi = "AIzaSyDKOL5Umy-IycVFcK5ZZyQQtUBGluEzWd0";
config.google_analytic_id = "G-DW1YX6RF1R"; // google_analytic Stream - QA

if (hostname == "localhost") {
  config.apiUrl = "http://localhost:3033";
  config.websocketURL = "http://localhost:3030";
  config.websocketAdhocURL = "http://localhost:3031";
  config.websocketDailyURL = "http://localhost:3036";
  config.websocketChatURL = "http://localhost:3024";
  config.env = "local";
} else {
  config.apiUrl = "https://" + hostname + "";
  if (hostname == "qa.auctionexpress.co.th") {
    config.env = "qa";
    config.websocketURL = "https://wsqa.auctionexpress.co.th";
    config.websocketAdhocURL = "https://wsadhocqa.auctionexpress.co.th";
    config.websocketDailyURL = "https://wsdailyqa.auctionexpress.co.th";
    config.websocketChatURL = "https://chatqa.auctionexpress.co.th";
  } else if (hostname == "dev.auctionexpress.co.th") {
    config.env = "dev";
    config.websocketURL = "https://wsdev.auctionexpress.co.th";
    config.websocketAdhocURL = "https://wsadhocdev.auctionexpress.co.th";
    config.websocketDailyURL = "https://wsdailydev.auctionexpress.co.th";
    config.websocketChatURL = "https://chatdev.auctionexpress.co.th";
  } else if (hostname == "192.168.20.48") {
    // for dev on Local server
    config.env = "dev";
    config.apiUrl = "http://192.168.20.48:3017";
    config.websocketURL = "http://192.168.20.48:3004";
    config.websocketAdhocURL = "http://192.168.20.48:3005";
    config.websocketDailyURL = "http://192.168.20.48:3006";
  } else if (
    hostname == "www.auctionexpress.co.th" ||
    hostname == "auctionexpress.co.th"
  ) {
    config.env = "prod";
    config.websocketURL = "https://ws.auctionexpress.co.th";
    config.websocketAdhocURL = "https://wsadhoc.auctionexpress.co.th";
    config.websocketDailyURL = "https://wsdaily.auctionexpress.co.th";
    config.google_analytic_id = "G-BXC1WY6P4F"; // google_analytic Stream - PRD
    config.websocketChatURL = "https://chat.auctionexpress.co.th";
  }
}

export default Object.freeze(Object.assign({}, config));

const loadState = (KEY_SESSION) => {
  try {
    const serializedState = sessionStorage.getItem(KEY_SESSION);
    if (serializedState === null) {
      return undefined;
    } else {
      return JSON.parse(serializedState);
    }
  } catch (error) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const {
      info_1,
      info_2,
      info_3,
      info_4,
      province_list,
      info_1_edit,
      info_2_edit,
      info_3_edit,
      info_4_edit,
      register_car_id_list,
      auction_type,
      status_profile_car,
      transportation,
      transportation_response,
      yard_address,
    } = state.register_car_reducer;

    sessionStorage.setItem(
      "register_car_data",
      JSON.stringify({
        info_1,
        info_2,
        info_3,
        info_4,
        province_list,
        info_1_edit,
        info_2_edit,
        info_3_edit,
        info_4_edit,
        register_car_id_list,
        auction_type,
        status_profile_car,
        transportation,
        transportation_response,
        yard_address,
      })
    );

    const serializedStateBuyersReducer = JSON.stringify(state.buyers);
    sessionStorage.setItem("register_data", serializedStateBuyersReducer);
    const serializedStateAuction = JSON.stringify(state.auction_reducer);
    sessionStorage.setItem("auction_data", serializedStateAuction);
    const serializedStateAddToCart = JSON.stringify(state.add_to_cart_reducer);
    sessionStorage.setItem("add_to_cart_data", serializedStateAddToCart);
  } catch (error) {
    // console.log(error.message);
  }
};

const removeStateLocal = (KEY_SESSION) => {
  try {
    localStorage.removeItem(KEY_SESSION);
  } catch (error) {
    // console.log(error.message);
  }
};

const removeStateSession = (KEY_SESSION) => {
  try {
    sessionStorage.removeItem(KEY_SESSION);
  } catch (error) {
    // console.log(error.message);
  }
};

export { loadState, saveState, removeStateLocal, removeStateSession };

import { buyers_constants } from "../_constants";

const init_state = {
  asideShow: false,
  form_sign_up: {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
  },
  form_membership: {
    member_ship_type: "",
    membership_tier: "",
    personal_title: "",
    first_name: "",
    last_name: "",
    nationality: "",
    id_card: "",
    birth_day: "",
    document_file: "",
    accept_condition: false,
  },
  registering: false,
  registerd: false,
  buyer: false,
  isRegular: false,
  register_fail: false,
  error: false,
  login: false,
  next_step: "",
  is_success: false,
  component: "",
  is_dealer_register: false,
  is_next_disabled: false,
  is_validate_membership_info: true,
  is_submit_form_info: false,
  // new form
  business_type_id: "",
  member_type_id: "",
  member_personal_detail: {
    title_id: "",
    first_name: "",
    last_name: "",
    nationality_id: "",
    id_card: "",
    birthday: "",
  },
  is_same_address: true,
  member_address: {
    address_no: "",
    province_id: "",
    district_id: "",
    sub_district_id: "",
    postcode: "",
  },
  member_address_delivery: {
    address_name: "",
    address_no: "",
    province_id: "",
    district_id: "",
    sub_district_id: "",
    postcode: "",
  },
  member_bank: {
    bank_id: "",
    bank_account_no: "",
    bank_account_name: "",
  },
  member_file_list: [],
  // for business company
  member_company_detail: {
    company_name: "",
    company_no: "",
  },
  member_company_board_list: [
    {
      id: 1,
      title_id: "",
      first_name: "",
      last_name: "",
    },
  ],
  member_company_admin_list: [
    {
      id: 1,
      title_id: "",
      first_name: "",
      last_name: "",
      position_id: "",
      position_other: "",
      email: "",
      phone: "",
    },
  ],
  member_company_file_list: [],
  user_bidding: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
  },
  invoice_id: "",
  is_free_trial: false,
  verify_message: "",
  consent_email_fail: false,
};

export function buyers(state = init_state, action) {
  switch (action.type) {
    case buyers_constants.SET_ASIDE_SHOW:
      return { asideShow: action.asideShow };
    case buyers_constants.REGISTER_REQUEST:
      return { registering: true };
    case buyers_constants.REGISTER_SUCCESS:
      return { registering: false, registerd: true, buyer: action.buyer };
    case buyers_constants.REGISTER_FAILURE:
      return { registering: false, register_fail: true, error: action.error };
    case buyers_constants.LOGIN_SUCCESS:
      return { login: true };
    case buyers_constants.MEMBERSHIP_STEP:
      return {
        ...state,
        next_step: action.next_step,
        is_success: action.is_success,
        component: action.component,
      };
    case buyers_constants.IS_NEXT_DISABLED:
      return {
        ...state,
        is_next_disabled: action.is_next_disabled,
      };
    case buyers_constants.IS_VALIDATE_MEMBERSHIP_INFO:
      return {
        ...state,
        is_validate_membership_info: action.is_validate_membership_info,
      };
    case buyers_constants.IS_SUBMIT_FORM_INFO:
      return {
        ...state,
        is_submit_form_info: action.is_submit_form_info,
      };
    case buyers_constants.FORM_SIGN_UP:
      return {
        ...state,
        form_sign_up: action.form_sign_up,
      };
    case buyers_constants.FORM_MEMBERSHIP:
      return {
        ...state,
        form_membership: action.form_membership,
      };
    case buyers_constants.IS_BUYER:
      return {
        ...state,
        buyer: action.buyer,
      };
    // for new form personal
    case buyers_constants.SET_BUSINESS_TYPE_ID:
      return {
        ...state,
        business_type_id: action.business_type_id,
      };
    case buyers_constants.SET_MEMBER_TYPE_ID:
      return {
        ...state,
        member_type_id: action.member_type_id,
      };
    case buyers_constants.SET_MEMBER_PERSONAL_DETAIL:
      return {
        ...state,
        member_personal_detail: action.member_personal_detail,
      };
    case buyers_constants.SET_MEMBER_ADDRESS:
      return {
        ...state,
        member_address: action.member_address,
      };
    case buyers_constants.SET_MEMBER_ADDRESS_DELIVERY:
      return {
        ...state,
        member_address_delivery: action.member_address_delivery,
      };
    case buyers_constants.SET_MEMBER_BANK:
      return {
        ...state,
        member_bank: action.member_bank,
      };
    case buyers_constants.SET_MEMBER_FILE_LIST:
      return {
        ...state,
        member_file_list: action.member_file_list,
      };
    case buyers_constants.SET_MEMBER_COMPANY_DETAIL:
      return {
        ...state,
        member_company_detail: action.member_company_detail,
      };
    case buyers_constants.SET_MEMBER_COMPANY_BOARD_LIST:
      return {
        ...state,
        member_company_board_list: action.member_company_board_list,
      };
    case buyers_constants.SET_MEMBER_COMPANY_ADMIN_LIST:
      return {
        ...state,
        member_company_admin_list: action.member_company_admin_list,
      };
    case buyers_constants.SET_MEMBER_COMPANY_FILE_LIST:
      return {
        ...state,
        member_company_file_list: action.member_company_file_list,
      };
    case buyers_constants.SET_IS_SAME_ADDRESS:
      return {
        ...state,
        is_same_address: action.is_same_address,
      };
    case buyers_constants.SET_USER_BIDDING:
      return {
        ...state,
        user_bidding: action.user_bidding,
      };
    case buyers_constants.SET_INVOICE_ID:
      return {
        ...state,
        invoice_id: action.invoice_id,
      };
    case buyers_constants.SET_FREE_TRIAL:
      return {
        ...state,
        is_free_trial: action.is_free_trial,
      };
    case buyers_constants.SET_VERIFY_MESSAGE:
      return {
        ...state,
        verify_message: action.verify_message,
      };
    case buyers_constants.SET_CONSENT_EMAIL_FAIL:
      return {
        ...state,
        consent_email_fail: action.consent_email_fail,
      };
    default:
      return state;
  }
}

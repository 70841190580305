export const buyers_constants = {
  SET_ASIDE_SHOW: "SET_ASIDE_SHOW",

  REGISTER_REQUEST: "BUYERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "BUYERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "BUYERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "BUYERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "BUYERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "BUYERS_LOGIN_FAILURE",

  LOGOUT: "BUYERS_LOGOUT",

  MEMBERSHIP_STEP: "MEMBERSHIP_STEP",
  MEMBERSHIP_STEP1: "/membership",
  MEMBERSHIP_STEP2: "/membership/membershipinfo",
  MEMBERSHIP_STEP3: "/membership/membershipdocument",
  MEMBERSHIP_STEP4: "/membership/membershipcondition",
  MEMBERSHIP_STEP5: "/membership/membershippayment",

  IS_NEXT_DISABLED: "IS_NEXT_DISABLED",
  IS_VALIDATE_MEMBERSHIP_INFO: "IS_VALIDATE_MEMBERSHIP_INFO",
  IS_SUBMIT_FORM_INFO: "IS_SUBMIT_FORM_INFO",
  IS_BUYER: "IS_BUYER",

  FORM_SIGN_UP: "FORM_SIGN_UP",
  FORM_MEMBERSHIP: "FORM_MEMBERSHIP",
  // for new form personal
  SET_BUSINESS_TYPE_ID: "SET_BUSINESS_TYPE_ID",
  SET_MEMBER_TYPE_ID: "SET_MEMBER_TYPE_ID",
  SET_MEMBER_PERSONAL_DETAIL: "SET_MEMBER_PERSONAL_DETAIL",
  SET_MEMBER_ADDRESS: "SET_MEMBER_ADDRESS",
  SET_MEMBER_ADDRESS_DELIVERY: "SET_MEMBER_ADDRESS_DELIVERY",
  SET_MEMBER_BANK: "SET_MEMBER_BANK",
  SET_MEMBER_FILE_LIST: "SET_MEMBER_FILE_LIST",
  SET_IS_SAME_ADDRESS: "SET_IS_SAME_ADDRESS",
  // for new form business company
  SET_MEMBER_COMPANY_DETAIL: "SET_MEMBER_COMPANY_DETAIL",
  SET_MEMBER_COMPANY_BOARD_LIST: "SET_MEMBER_COMPANY_BOARD_LIST",
  SET_MEMBER_COMPANY_ADMIN_LIST: "SET_MEMBER_COMPANY_ADMIN_LIST",
  SET_MEMBER_COMPANY_FILE_LIST: "SET_MEMBER_COMPANY_FILE_LIST",
  SET_USER_BIDDING: "SET_USER_BIDDING",
  SET_INVOICE_ID: "SET_INVOICE_ID",
  SET_FREE_TRIAL: "SET_FREE_TRIAL",
  SET_VERIFY_MESSAGE: "SET_VERIFY_MESSAGE",
  SET_CONSENT_EMAIL_FAIL: "SET_CONSENT_EMAIL_FAIL",
};

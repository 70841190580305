import React ,{useEffect} from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import "../scss/style.scss";
import { history } from "../_helpers";
import "../fonts/line-awesome.min.css";
import ReactGA4 from "react-ga4";
import config from "../../src/configs/app";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
const TheLayout = React.lazy(() => import("../layout/TheLayout"));
const QrCar = React.lazy(() =>
  import("../features/register_car/_views/qr_car")
);
const PageNotFound = React.lazy(() =>
  import("../features/register_car/_views/not_found")
);
const EntryPage = React.lazy(() =>
  import("../features/auction/_views/entry_page")
);

function App() {

  ReactGA4.initialize(config.google_analytic_id);

  return (
    <Router forceRefresh={true} history={history}>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            path="/page_not_found"
            name="PageNotFound"
            render={(props) => <PageNotFound {...props} />}
          />
          <Route
            path="/qr_car/:car_token"
            name="QrCar"
            render={(props) => <QrCar {...props} />}
          />
          <Route
            exact
            path="/"
            name="EntryPage"
            render={(props) => <EntryPage {...props} />}
          />
          {/* another path route */}
          <Route render={(props) => <TheLayout {...props} />} />
          {/* <Redirect from="*" to="/calendar" /> */}
        </Switch>
      </React.Suspense>
    </Router>
  );
}
export { App };

import { add_to_cart_constants } from "../_constants";

const init_state = {
  auction_type: "1",
  province_list: [],
  transportation: {
    service_type: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    transportation_date: "",
    address_no: "",
    province_id: "",
    district_id: "",
    sub_district_id: "",
    postcode: "",
    address_phone: "",
    latitude: "",
    longitude: "",
    yard_id: "1",
    have_receipt: false,
    receipt_first_name: "",
    receipt_last_name: "",
    receipt_email: "",
    receipt_phone: "",
    receipt_address_no: "",
    receipt_province_id: "",
    receipt_district_id: "",
    receipt_sub_district_id: "",
    receipt_postcode: "",
    receipt_id_card: "",
  },
  register_car_id_list: [],
  transportation_response: {
    transportation_no: "",
    transportation_id: "",
    service_type: "",
    transportation_date: "",
    yard_name: "",
  },
  total_price: 0,
};

const add_to_cart_reducer = (state = init_state, action) => {
  switch (action.type) {
    case add_to_cart_constants.SET_PROVINCE_LIST:
      return {
        ...state,
        province_list: action.province_list,
      };
    case add_to_cart_constants.SAVE_TRANSPOTATION:
      return {
        ...state,
        transportation: action.form_data,
      };
    case add_to_cart_constants.SAVE_REGISTER_CAR_ID_LIST:
      return {
        ...state,
        register_car_id_list: action.register_car_id_list,
      };
    case add_to_cart_constants.SAVE_TRANSPORTATION_RESPONSE:
      return {
        ...state,
        transportation_response: action.transportation_response,
      };
    case add_to_cart_constants.SAVE_TOTAL_PRICE:
      return {
        ...state,
        total_price: action.totol_price,
      };
    case add_to_cart_constants.CLEAR_TRANSPORTATION_FORM:
      return {
        ...state,
        transportation: init_state.transportation,
        register_car_id_list: [],
        total_price: 0,
      };
    case add_to_cart_constants.SET_AUCTION_TYPE:
      return {
        ...state,
        auction_type: action.auction_type,
      };
    default:
      return state;
  }
};

export default add_to_cart_reducer;

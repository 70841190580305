import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { combineReducers } from "redux";

// reducers
import { buyers } from "../features/register/_reducers";
import { register_car_reducer } from "../features/register_car/_reducers";
import { auction_reducer } from "../features/auction/_reducers";
import { add_to_cart_reducer } from "../features/add_to_cart/_reducers";

// session storage
import {
  loadState,
  saveState,
} from "../features/register_car/register_car_storage";

const loggerMiddleware = createLogger();

const rootReducer = combineReducers({
  buyers,
  register_car_reducer,
  auction_reducer,
  add_to_cart_reducer,
});
const persistStore = {
  buyers: loadState("register_data"),
  register_car_reducer: loadState("register_car_data"),
  auction_reducer: loadState("auction_data"),
  add_to_cart_reducer: loadState("add_to_cart_data"),
};

const store = createStore(
  rootReducer,
  persistStore,
  applyMiddleware(thunkMiddleware, loggerMiddleware)
);

store.subscribe(() => {
  saveState(store.getState());
});

export default store;

import { register_car_constants } from "../_constants";

const init_state = {
  info_1: {
    vat_type_id: 1,
    car_category_id: "",
    contract_number: "",
    registration_date: "",
    register_year: "",
    registration_province_id: "",
    registration_type_id: "",
    registration_number: "",
    registration_number_1: "",
    registration_number_2: "",
    ownership_order_id: "",
    vin_number: "",
    engine_number: "",
    insurance_expire_date: "",
    tax_expire_date: "",
    car_type_id: "",
    pick_up_type_id: "",
    brand_id: "",
    model_id: "",
    sub_model_id: "",
    model_other: "",
    sub_model_other: "",
    manufactured_year: "",
    kilometer: "",
    car_color_id: "",
    gear_system: "",
    car_fuel_type_id: "",
    car_color_other: "",
    details: "",
    transfer_conditions: "",
    file_upload: [],
    engine_capacity: "",
    remaining_oil: "",
    gas_tank_no: "",
  },
  info_2: {
    accessories_checkbox_list: [
      {
        accessories_checkbox_id: 1,
      },
      {
        accessories_checkbox_id: 2,
      },
      {
        accessories_checkbox_id: 4,
      },
      {
        accessories_checkbox_id: 6,
      },
      {
        accessories_checkbox_id: 7,
      },
      {
        accessories_checkbox_id: 9,
      },
      {
        accessories_checkbox_id: 10,
      },
      {
        accessories_checkbox_id: 11,
      },
      {
        accessories_checkbox_id: 12,
      },
      {
        accessories_checkbox_id: 13,
      },
      {
        accessories_checkbox_id: 14,
      },
      {
        accessories_checkbox_id: 8,
      },
    ],
    accessories_dropdown_list_select: [],
    accessories_extra_list_select: [],
    additional_cost_list_select: [],
  },
  info_3: {
    first_name: "",
    last_name: "",
    registration_book: [],
    annual_tax_item: [],
    transport_staff_record: [],
    noti_not_yet_register: [],
    insurance_policy_document: [],
    motor_vehicle_act: [],
  },
  info_4: {
    auction_type: "weekly",
    satellite_id: "",
    area_id: "",
    transfer_conditions_type: "",
    continuous_auction: false,
    reserve_price: "",
    buy_it_now_price: "",
    message_to_ax: "",
    time_type: "immediately",
    auction_date: "",
    auction_time: "",
    auction_time_specify: "",
  },
  info_1_edit: {
    vat_type_id: 1,
    car_category_id: "",
    contract_number: "",
    registration_date: "",
    register_year: "",
    registration_province_id: "",
    registration_type_id: "",
    registration_number: "",
    registration_number_1: "",
    registration_number_2: "",
    ownership_order_id: "",
    vin_number: "",
    engine_number: "",
    insurance_expire_date: "",
    tax_expire_date: "",
    car_type_id: "",
    pick_up_type_id: "",
    brand_id: "",
    model_id: "",
    sub_model_id: "",
    model_other: "",
    sub_model_other: "",
    manufactured_year: "",
    kilometer: "",
    car_color_id: "",
    gear_system: "",
    car_fuel_type_id: "",
    car_color_other: "",
    details: "",
    transfer_conditions: "",
    file_upload: [],
    engine_capacity: "",
    remaining_oil: "",
    gas_tank_no: "",
  },
  info_2_edit: {
    accessories_checkbox_list: [
      {
        accessories_checkbox_id: 1,
      },
      {
        accessories_checkbox_id: 2,
      },
      {
        accessories_checkbox_id: 4,
      },
      {
        accessories_checkbox_id: 6,
      },
      {
        accessories_checkbox_id: 7,
      },
      {
        accessories_checkbox_id: 9,
      },
      {
        accessories_checkbox_id: 10,
      },
      {
        accessories_checkbox_id: 11,
      },
      {
        accessories_checkbox_id: 12,
      },
      {
        accessories_checkbox_id: 13,
      },
      {
        accessories_checkbox_id: 14,
      },
      {
        accessories_checkbox_id: 8,
      },
    ],
    accessories_dropdown_list_select: [],
    accessories_extra_list_select: [],
    additional_cost_list_select: [],
  },
  info_3_edit: {
    first_name: "",
    last_name: "",
    registration_book: [],
    annual_tax_item: [],
    transport_staff_record: [],
    noti_not_yet_register: [],
    insurance_policy_document: [],
    motor_vehicle_act: [],
  },
  info_4_edit: {
    auction_type: "weekly",
    satellite_id: "",
    area_id: "",
    transfer_conditions_type: "",
    continuous_auction: false,
    reserve_price: "",
    buy_it_now_price: "",
    message_to_ax: "",
    time_type: "immediately",
    auction_date: "",
    auction_time: "",
    auction_time_specify: "",
  },
  province_list: [],
  transportation: {
    service_type: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    transportation_date: "",
    address_no: "",
    province_id: "",
    district_id: "",
    sub_district_id: "",
    postcode: "",
    address_phone: "",
    latitude: "",
    longitude: "",
    yard_id: "1",
    have_receipt: false,
    receipt_first_name: "",
    receipt_last_name: "",
    receipt_email: "",
    receipt_phone: "",
    receipt_address_no: "",
    receipt_province_id: "",
    receipt_district_id: "",
    receipt_sub_district_id: "",
    receipt_postcode: "",
    receipt_id_card: "",
  },
  auction_type: "weekly",
  status_profile_car: "wait_pay",
  register_car_id_list: [],
  transportation_response: {
    transportation_no: "",
    transportation_id: "",
    service_type: "",
    transportation_date: "",
    yard_name: "",
  },
  yard_address: {
    address: "บริษัท อ็อคชั่น เอ็กซ์เพรส จํากัด ที่อยู่ 119 หมู่ที่ 7",
    province_name: "สมุทรปราการ",
    district_name: "บางเสาธง",
    sub_district_name: "บางเสาธง",
    postcode: 10570,
    lat: 13.590993,
    lng: 100.825679,
  },
};

const register_car_reducer = (state = init_state, action) => {
  switch (action.type) {
    case register_car_constants.SAVE_REGISTER_CAR_INFO_1:
      return {
        ...state,
        info_1: action.register_car_info_1_form,
      };
    case register_car_constants.SAVE_REGISTER_CAR_INFO_2:
      return {
        ...state,
        info_2: action.register_car_info_2_form,
      };
    case register_car_constants.SAVE_REGISTER_CAR_INFO_3:
      return {
        ...state,
        info_3: action.register_car_info_3_form,
      };
    case register_car_constants.SAVE_REGISTER_CAR_INFO_4:
      return {
        ...state,
        info_4: action.register_car_info_4_form,
      };
    case register_car_constants.SAVE_REGISTER_CAR_INFO_1_EDIT:
      return {
        ...state,
        info_1_edit: action.form_data,
      };
    case register_car_constants.SAVE_REGISTER_CAR_INFO_2_EDIT:
      return {
        ...state,
        info_2_edit: action.form_data,
      };
    case register_car_constants.SAVE_REGISTER_CAR_INFO_3_EDIT:
      return {
        ...state,
        info_3_edit: action.form_data,
      };
    case register_car_constants.SAVE_REGISTER_CAR_INFO_4_EDIT:
      return {
        ...state,
        info_4_edit: action.form_data,
      };
    case register_car_constants.SET_PROVINCE_LIST:
      return {
        ...state,
        province_list: action.province_list,
      };
    case register_car_constants.SAVE_AUCTION_TYPE:
      return {
        ...state,
        auction_type: action.auction_type,
      };
    case register_car_constants.SAVE_STATUS_PROFILE_CAR:
      return {
        ...state,
        status_profile_car: action.status_profile_car,
      };
    case register_car_constants.SAVE_TRANSPOTATION:
      return {
        ...state,
        transportation: action.form_data,
      };
    case register_car_constants.SAVE_YARD_ADDRESS:
      return {
        ...state,
        yard_address: action.yard_address,
      };
    case register_car_constants.SAVE_REGISTER_CAR_ID_LIST:
      return {
        ...state,
        register_car_id_list: action.register_car_id_list,
      };
    case register_car_constants.SAVE_TRANSPORTATION_RESPONSE:
      return {
        ...state,
        transportation_response: action.transportation_response,
      };
    case register_car_constants.CLEAR_TRANSPORTATION_FORM:
      return {
        ...state,
        auction_type: "weekly",
        transportation: init_state.transportation,
        register_car_id_list: [],
      };

    case register_car_constants.CLEAR_FORM_REGISTER_CAR:
      return {
        ...init_state,
        province_list: state.province_list,
      };
    default:
      return state;
  }
};

export default register_car_reducer;

import { auction_constants } from "../_constants";

const init_state = {
  bidding_price: 500000,
  is_mute_notification: false,
};

const auction_reducer = (state = init_state, action) => {
  switch (action.type) {
    case auction_constants.BIDDING_PRICE:
      return {
        ...state,
        bidding_price: action.bidding_price,
      };
    case auction_constants.IS_MUTE_NOTIFICATION:
      return {
        ...state,
        is_mute_notification: action.is_mute_notification,
      };

    default:
      return state;
  }
};

export default auction_reducer;
